import { useMsal } from "@azure/msal-react";
import logo from "./assets/images/logo.png";
import "./FeamNavBar.css";
import * as BootStrapIcon from "react-bootstrap-icons";
import { feamOAuthInfo, feamUserInfo } from "./models/constants";
import * as utils from "./models/feam-utils";
import { useEffect, useState } from "react";
import { FeamEntities } from "./models/feam-entities";

export default function FeamNavBar() {
  const { instance, accounts } = useMsal();
  const [user, setUser] = useState<FeamEntities.ID407userRole | undefined>(localStorage.getItem(feamUserInfo) ? JSON.parse(localStorage.getItem(feamUserInfo) ?? '') : undefined);
  const [verticalMenu, setVerticalMenu] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem(feamUserInfo)) {
        //@ts-ignore
        setUser(JSON.parse(localStorage.getItem(feamUserInfo)));
      }
    },
      500)
  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-dark feam-navbar feam-navbar-bg-color">
      <div className="container-fluid ">
        <a
          className="navbar-brand"
          href={`${process.env.REACT_APP_REDIRECT_URI}/`}
        >
          <img src={logo} alt="" width="162" height="65" className="" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { setVerticalMenu(p => !p) }}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${verticalMenu ? "show" : ""}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-0 mb-lg-0  gap-3">
            {!utils.isBillingApprover(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive(" ") ? "active" : ""}`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/`}
                  rel="noreferrer">
                  D407Classic
                </a>
              </li>
            )}
            {!utils.isBillingApprover(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("dynamic") ? "active" : ""}`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/dynamic`}
                  rel="noreferrer">
                  D407Dynamic
                </a>
              </li>
            )}

            <li className="nav-item">
              <a
                className={`nav-link text-uppercase ${isActive("search") ? "active" : ""}`}
                aria-current="page"
                href={`${process.env.REACT_APP_REDIRECT_URI}/search`}
                rel="noreferrer">
                Search
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link text-uppercase ${isActive("reports") ? "active" : ""}`}
                aria-current="page"
                href={`${process.env.REACT_APP_REDIRECT_URI}/reports`}
                rel="noreferrer">
                Reports
              </a>
            </li>
            {(utils.isSuperAdmin(user) || utils.isBillingApprover(user) || utils.isPowerUser(user)) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("billing") ? "active" : ""
                    }`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/billing`}
                  rel="noreferrer">
                  Billing
                </a>
              </li>
            )}

            {(utils.isSuperAdmin(user) || utils.isBillingApprover(user) || utils.isPowerUser(user) || utils.isStationAdmin(user)) && (
              <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle text-uppercase ${showAdmin ? "show" : ""}   ${isAdminActive() ? "active" : ""}`} href="#" role="button" onClick={() => { setShowAdmin(p => !p) }}>
                  Admin
                </a>
                <ul className={`submenu dropdown-menu feam-navbar-submenu ${showAdmin ? "show" : ""}`}>
                  {(utils.isSuperAdmin(user) || utils.isBillingApprover(user) || utils.isPowerUser(user)
                ||   utils.isStationAdmin(user)
                ) && (
                    <li className="dropdown-item">
                      <a
                        className={`nav-link text-uppercase text-nowrap ${isActive("timeandlabor") ? "active" : ""
                          }`}
                        aria-current="page"
                        href={`${process.env.REACT_APP_REDIRECT_URI}/admin/timeandlabor`}
                        rel="noreferrer">
                        Time & Labor
                      </a>
                    </li>
                  )}
                  {(utils.isSuperAdmin(user) || utils.isBillingApprover(user)) && (
                    <li className="dropdown-item">
                      <a
                        className={`nav-link text-uppercase ${isActive("status") ? "active" : ""}`}
                        aria-current="page"
                        href={`${process.env.REACT_APP_REDIRECT_URI}/admin/status`}
                        rel="noreferrer">
                        Status
                      </a>
                    </li>
                  )}
                  {utils.isSuperAdmin(user) && (
                    <li className="dropdown-item">
                      <a
                        className={`nav-link text-uppercase ${isActive("users") ? "active" : ""}`}
                        aria-current="page"
                        href={`${process.env.REACT_APP_REDIRECT_URI}/admin/users`}
                        rel="noreferrer">
                        Users
                      </a>
                    </li>
                  )}
                  {utils.isSuperAdmin(user) && (
                    <li className="dropdown-item">
                      <a
                        className={`nav-link text-uppercase ${isActive("customersync") ? "active" : ""}`}
                        aria-current="page"
                        href={`${process.env.REACT_APP_REDIRECT_URI}/admin/customersync`}
                        rel="noreferrer">
                        Customers Sync
                      </a>
                    </li>
                  )}
                </ul>
              </li>)}
            <li className="nav-item">
              <a
                className={`nav-link text-uppercase ${isActive("help") ? "active" : ""}`}
                href={`${process.env.REACT_APP_REDIRECT_URI}/help`}
                rel="noreferrer">
                Help
              </a>
            </li>
          </ul>
        </div>
        <div className={`d-flex  align-items-center text-nowrap`}>
          <span className="text-white">Welcome: {accounts[0]?.name}</span>
          <a
            role="button"
            className="ms-1 text-nowrap"
            title="Sign Out"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              signOutHandler();
            }}
            style={{ color: "#ff690d" }}
            rel="noreferrer">
            <span>
              <BootStrapIcon.BoxArrowRight size={24} className="me-1" />
              Sign Out
            </span>
          </a>
        </div>
      </div>
    </nav>
  );

  function signOutHandler() {
    if (window.confirm(`Are you sure! You want to Sign Out.`)) {
      instance.logoutRedirect({ account: instance.getActiveAccount() });
      localStorage.setItem(feamUserInfo, "");
      localStorage.setItem(feamOAuthInfo, "");
    }
  }
  function isAdminActive() {
    return isActive("users") || isActive('timeandlabor') || isActive('status') || isActive('customersync');
  }

  function isActive(page: string) {
    const path = window.location.pathname;
    if (
      page === " " &&
      !path.includes("search") &&
      !path.includes("help") &&
      !path.includes("billing") &&
      !path.includes("status") &&
      !path.includes("reports") &&
      !path.includes("users") &&
      !path.includes("dynamic") &&
      !path.includes("timeandlabor") &&
      !path.includes("customersync")
    ) {
      return true;
    }
    if (path.includes(page)) {
      return true;
    }
  }
}
