import { useEffect, useRef, useState } from "react";
import * as factories from "../../../models/factories";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import { useMsal } from "@azure/msal-react";
import { CwsFooterControls, CwsGeneralSection, CwsHeaderSection, CwsSearchSection, SummaryContainer } from "../common";
import { IResultStatus, customerService, customerWorksheetService, feamEmployeeService, maintenanceTypeService, oAuth2TokenForWebhookService, resultStatus, userRoleService, webhookService } from "../../../services";
import CwsDynamicTailContainer from "./CwsDynamicTailContainer";
import { FeamFormInput } from "../../common";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import CwsDynamicMechanicsContainer from "./CwsDynamicMechanicsContainer";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import stationService from "../../../services/station-service";
import { pageTitle, processingStatusType, userRoleType } from "../../../models/constants";
import { useBetween } from 'use-between'
import { useFeamAuth, useFeamDirty, useFeamReloadHook } from "../../hooks";
import CwsDynamicPersonalExpenses from "./CwsDynamicPersonalExpenses";
import { useTokenState } from "../../../RequestInterceptors";
import { usePrint } from "../../../PageLayout";
import { isMobile,isIOS } from "react-device-detect";
import { formatDateTimeForApi, getTimeInAMAndPM, padTo2Digits } from "../../../models/feam-utils";
import * as utils from "../../../models/feam-utils";
import moment from "moment";

const initialLoginUserDetail: { email: string, displayName: string, stationCode: string; stationId: number | undefined } = { email: '', displayName: '', stationCode: '', stationId: undefined };
const initialState: { formProps: FormikProps<FeamEntities.ID407Data> | undefined, action: 'create' | 'update' | 'print' | undefined, status: IResultStatus | undefined } = { formProps: undefined, action: undefined, status: undefined };
const initialPrintState: { state: boolean, id: number | undefined, formikHelpers: FormikHelpers<FeamEntities.ID407Data> | undefined } = { state: false, id: undefined, formikHelpers: undefined };
export const useSharedState = () => useBetween(useFeamReloadHook);
export const useDirty = () => useBetween(useFeamDirty);
export default function DynamicWorkSheet(props: IProps.IDynamicWorkSheetProps) {
  const [worksheet, setWorksheet] = useState(factories.getD407DynamicData());
  const [print, setPrint] = useState(initialPrintState);
  const [saveAction, setSaveAction] = useState(initialState);
  const [workSheetId, setWorkSheetId] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const { isPrint, setIsPrint } = usePrint();
  const { instance, accounts } = useMsal();
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState<FeamEntities.IFeamEmployeeForAutoInput[]>([]);
  const [customers, setCustomers] = useState<IDropdown[]>([]);
  const [stations, setStations] = useState<IDropdown[]>([]);
  const [loginUserDetail, setLoginUserDetail] = useState(initialLoginUserDetail);
  const [maintenanceType, setMaintenanceType] = useState<{ maintenanceTypesForDropdown: IDropdown[], maintenanceTypes: FeamEntities.IMaintenanceType[] }>({ maintenanceTypesForDropdown: [], maintenanceTypes: [] });
  const feamAuth: { user: FeamEntities.ID407userRole | undefined } = useFeamAuth();
  const { isDirty, setIsDirty } = useDirty();
  //not able to update customer work sheet after final approval
  const [lock, setLock] = useState(false);
  const isSaveClickedRef = useRef(false);
  const { isTokenSet } = useTokenState();

  useEffect(() => {
    if (isTokenSet && !isLoaded) {
      const fetchData = async () => {
        await extractDataFromAD();
      }
      fetchData();
    }
  }, [isTokenSet])

  useEffect(() => {
    if (print.state) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        printHandler();
      }, 700);
    }
  }, [print.state]);

  useEffect(() => {
    if (users.length > 0) {
      const fetchData = async () => {
        let id = getD407IdFromQueryString();
        if (id) {
          await customerWorksheetById(id);
        }
      }
      fetchData();
    }
  }, [users]);

  useEffect(() => {
    if (props.d407Data) {
      const fetchData = async () => {
        //@ts-ignore
        const data = await reArrangeD407Data(props.d407Data, true);
        //@ts-ignore
        setWorksheet(data);
      }
      fetchData();
    }
  }, [props.d407Data]);

  return (
    <LoadingOverlayWrapper className="feam-postion-fixed" active={!isLoaded} text={"Loading Content..."} spinner>
      <Formik
        initialValues={worksheet}
        enableReinitialize={true}
        onSubmit={async (
          values: FeamEntities.ID407Data,
          formikHelpers: FormikHelpers<FeamEntities.ID407Data>
        ) => {
          await saveCustomWorksheet(JSON.parse(JSON.stringify(values)), formikHelpers);
        }}
      >
        {(formProps: FormikProps<FeamEntities.ID407Data>) => {
          return (
            <Form>
              <fieldset disabled={formProps.isSubmitting}>
                <div className={(!print.state && !isPrint) ? "d-flex d-inline-flex" : ""}>
                  <div className="customer-worksheet-container customer-container-width customer-worksheet-container-min-height">
                    <div className="d-flex flex-column customer-worksheet-table-container">
                      <CwsHeaderSection station={formProps.values.stationCode} d407Id={formProps.values.d407id} />
                      {(!print.state && !isPrint) && <CwsSearchSection formProps={formProps} customerWorksheetById={customerWorksheetById} workSheetId={workSheetId ?? ""} onClear={() => clearHandler(formProps)} resetForm={() => resetData()} />}
                      <CwsGeneralSection formProps={formProps} isDynamic={true} maintenanceTypesForDropdown={maintenanceType.maintenanceTypesForDropdown} maintenanceTypes={maintenanceType.maintenanceTypes} />
                      <CwsDynamicTailContainer formProps={formProps} print={(print.state || isPrint) ? true : false} maintenanceTypes={maintenanceType.maintenanceTypes} station={stations.find(x => x.value == formProps.values.stationId)?.text ?? ''} airlineName={customers.find(x => x.value == formProps.values.customerId)?.text ?? ''} flightDate={formProps.values.entryDate ?? ''} />
                      {formProps.values.maintenanceTypeId && maintenanceType.maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.isJobNumberType && <CwsDynamicPersonalExpenses formProps={formProps} users={users} print={(print.state || isPrint) ? true : false} />}
                      <CwsDynamicMechanicsContainer formProps={formProps} print={(print.state || isPrint) ? true : false} users={users} maintenanceTypes={maintenanceType.maintenanceTypes} />
                      <SummaryContainer formProps={formProps} />
                      <div className="d-flex flex-row justify-content-start align-items-start pt-2 h-100">
                        <div className="d-flex ps-2 gap-5">
                          <div className="d-flex flex-column justify-content-start align-items-start">
                            <FeamFormInput formProps={formProps} feamSelector="preparedBy" className="customer-worksheet-form-control" readOnly={true} />
                            <label className="col-form-label justify-content-start">Prepared By:</label>
                          </div>
                          <div className="d-flex flex-column justify-content-start align-items-start">
                            <FeamFormInput formProps={formProps} feamSelector="updatedBy" className="customer-worksheet-form-control" readOnly={true} />
                            <label className="col-form-label justify-content-start">Updated By:</label>
                          </div>
                          <div className="d-flex  flex-column justify-content-start align-items-start">
                            <FeamFormInput formProps={formProps} feamSelector="approvedBy" className="customer-worksheet-form-control" readOnly={true} />
                            <label className="col-form-label">Approved By:</label>
                          </div>
                          <div className="d-flex  flex-column justify-content-start align-items-start">
                            <FeamFormInput formProps={formProps} feamSelector="recordedBy" className="customer-worksheet-form-control" readOnly={lock} />
                            <label className="col-form-label">Recorded By:</label>
                          </div>
                          <div className="d-flex  flex-column justify-content-start align-items-start">
                            <FeamFormInput type="date" formProps={formProps} feamSelector="dateCompleted" className="customer-worksheet-form-control" readOnly={lock} />
                            <label className="col-form-label">Date:</label>
                          </div>
                          {(utils.isSuperAdmin(feamAuth.user) || ((utils.isStationAdmin(feamAuth.user) || utils.isStationApprover(feamAuth.user)) && feamAuth.user?.stationIds?.includes(formProps.values.stationId ?? 0))) && <div className="d-flex  flex-column justify-content-start align-items-start">
                            <input name="isApproved" className="form-check-input mb-1" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formProps.values.isApproved ?? false} onChange={(e) => { approveHandler(e, formProps); }} disabled={lock} />
                            <label className="col-form-label" htmlFor="flexSwitchCheckDefault"> Approved: </label>
                          </div>
                          }
                          {!(print.state || isPrint) && <div className="d-flex flex-row justify-content-center align-items-start">
                            <CwsFooterControls d407Id={workSheetId} saving={saving} instance={instance} lock={lock} onSaveClick={() => { saveHandler(formProps) }} />
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </Form>)
        }}
      </Formik>
      <Modal isOpen={saveAction.status !== undefined}>
        <ModalBody className="p-3">
          {getResultStatusMessage()}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={() => {
            //@ts-ignore
            if (saveAction.status !== resultStatus.failed) {
              setPrint(p => ({ ...p, state: true }));
            }
            setSaveAction(p => ({ ...p, status: undefined }));
            setIsPrint(true);
          }} >OK</button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={saveAction.action !== undefined && workSheetId !== undefined}>
        <ModalBody className="p-3">
          {`Click Create to Create new record or ${saveAction.formProps?.dirty ? "Update to Update the existing record" : "Print to print the existing record"} or CANCEL to return to the page`}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('create')} disabled={saving}>
            {saving && saveAction.action === "create" ? (<>  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Creating...</>) : "Create New"}
          </button>
          {(saveAction.formProps?.dirty || isDirty) && <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('update')} disabled={saving}>
            {saving && saveAction.action === "update" ? (<>  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Updating...</>) : "Update"}
          </button>}
          {(!saveAction.formProps?.dirty && !isDirty) && <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('print')} disabled={saving}>Print-No Update</button>}
          <button type="button" className="btn btn-outline-secondary" onClick={() => actionHandler('cancel')} disabled={saving}>Cancel</button >
        </ModalFooter>
      </Modal>
    </LoadingOverlayWrapper>
  )

  function getResultStatusMessage() {
    switch (saveAction.status) {
      //@ts-ignore
      case resultStatus.saved:
        return 'Customer worksheet has saved successfully.';
      //@ts-ignore
      case resultStatus.updated:
        return 'Customer worksheet has updated successfully.';
      //@ts-ignore
      case resultStatus.failed:
        return 'Customer worksheet has failed. Please try again!';
    }
  }

  function clearAfterPrint(e: any) {
    let id = getD407IdFromQueryString();
    if (id) {
      setWorkSheetId(id);
    } else {
      document.title = pageTitle.dynamicD407Form;
      const d407NewData = resetData();
      print.formikHelpers?.resetForm({ values: d407NewData });
      setWorksheet(d407NewData);
      setWorkSheetId(undefined);
    }
    setPrint({ state: false, id: undefined, formikHelpers: undefined });
    setIsPrint(false);
  }

  function printHandler() {
    window.addEventListener(isMobile ? "focus" : "afterprint", clearAfterPrint);
    window.print();
    return () => {
      window.removeEventListener(isMobile ? "focus" : "afterprint", clearAfterPrint);
    }
  }
  function getD407IdFromQueryString() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      //@ts-ignore
      get: (searchParams: URLSearchParams, prop: string) => searchParams.get(prop),
    });
    //@ts-ignore
    return params.id;
  }

  function clearHandler(formProps: FormikProps<FeamEntities.ID407Data>) {
    let id = getD407IdFromQueryString();
    if (id) {
      let url = window.location.protocol + '//' + window.location.host + "/dynamic";
      window.open(url, "_self");
      return;
    }
    const d407Data = resetData();
    formProps.resetForm({ values: d407Data });
    setWorkSheetId(undefined);
    setLock(false);
  }

  function resetData() {
    const d407Data = factories.getD407DynamicData();
    d407Data.username = loginUserDetail.email;
    d407Data.preparedBy = loginUserDetail.displayName;
    d407Data.updatedBy = loginUserDetail.displayName;
    d407Data.displayName = loginUserDetail.displayName;
    d407Data.stationId = loginUserDetail.stationId;
    d407Data.stationCode = loginUserDetail.stationCode;
    return d407Data;
  }

  function actionHandler(action: 'create' | 'update' | 'cancel' | 'print') {
    if (isSaveClickedRef.current) {
      return;
    }
    if (action === "cancel") {
      alert('You have clicked the cancel button. Your changes have not been saved.');
      setSaveAction({ formProps: undefined, action: undefined, status: undefined });
      setIsPrint(false);
    }
    else if (action === "create") {
      isSaveClickedRef.current = true;
      saveAction.formProps?.setFieldValue("status", null);
      saveAction.formProps?.setFieldValue("approvedBy", '');
      saveAction.formProps?.setFieldValue("D407Id", undefined);
      saveAction.formProps?.submitForm();
      setSaveAction(prev => ({ ...prev, action: action }));
    }
    else if (action === "update") {
      isSaveClickedRef.current = true;
      saveAction.formProps?.submitForm();
      setSaveAction(prev => ({ ...prev, action: action }));
    }
    else if (action === "print") {
      setFileTitle(worksheet);
      setSaveAction({ formProps: undefined, action: undefined, status: undefined });
      setPrint(p => ({ ...p, state: true }));
      setIsPrint(true);
    }
    setIsDirty(false);
  }

  function isValid(formProps: FormikProps<FeamEntities.ID407Data>) {
    if (!formProps.values.customerId) {
      alert('The form requires a Customer name for submission');
      return false;
    }
    if (!formProps.values.stationId) {
      alert('The form requires a Station for submission');
      return false;
    }
    if (!formProps.values.maintenanceTypeId) {
      alert('The form requires a MaintenanceType for submission');
      return false;
    }
    if (formProps.values.tailDetails?.findIndex(x => x.tail && x.tail !== '') === -1) {
      alert('The form requires at least one Tail for submission');
      return false;
    }
    return true;
  }
  function saveHandler(formProps: FormikProps<FeamEntities.ID407Data>) {
    if (!isValid(formProps) || isSaveClickedRef.current) {
      return;
    }
    if (!formProps.values.d407id) {
      if (window.confirm('Ready to save your form! Click "OK" to Save or "Cancel" to continue editing.') === false) {
        return;
      }
      isSaveClickedRef.current = true;
      formProps?.submitForm();
      setSaveAction(prev => ({ ...prev, formProps: formProps, action: 'create' }));
    } else {
      setSaveAction({ formProps: formProps, action: 'update', status: undefined });
    }
  }

  async function saveCustomWorksheet(data: FeamEntities.ID407Data, formikHelpers: FormikHelpers<FeamEntities.ID407Data>) {
    setSaving(true);
    if (!utils.trimObjectProps(data)) {
      setSaving(false);
      setSaveAction(prev => ({ ...prev, status: resultStatus.failed as unknown as IResultStatus }));
      return;
    }
    const currentDate = new Date();
    const time = getTimeInAMAndPM(currentDate);
    if (data.entryDate?.length === 10) {
      data.entryDate = data.entryDate + " " + time;
    }
    if (data.dateCompleted?.length === 10) {
      data.dateCompleted = data.dateCompleted + " " + time;
    }
    data.entryDate = moment(isIOS ? data.entryDate?.replace(/-/g, "/") : data.entryDate).format('MM/DD/YYYY hh:mm A');
    data.dateCompleted = moment(isIOS? data.dateCompleted?.replace(/-/g, "/") : data.dateCompleted).format('MM/DD/YYYY hh:mm A');
    data.systemDateTime = moment(currentDate.toString()).format('MM/DD/YYYY hh:mm A');

    data.d407id = saveAction.action === "update" ? data.d407id : undefined;
    if (!data.d407id) {
      data.createUtc = currentDate;
      data.createUtcOffset = currentDate.getTimezoneOffset();
      data.preparedBy = data.updatedBy;
    }
    data.updateUtc = currentDate;
    data.updateUtcOffset = currentDate.getTimezoneOffset();
    data.mechanicsAssigneds = data.mechanicsAssigneds?.map((x, mIndex) => {
      x.d407id = saveAction.action === "update" ? x.d407id : undefined;
      x.mechanicsAssignedId = undefined;
      x.orderNo = mIndex + 1;
      if (data.isWorkShift) {
        x.shiftStartTime = undefined;
        x.shiftEndTime = undefined;
      } else {
        x.shiftStartDate = undefined;
        x.shiftEndDate = undefined;
      }
      if (x.shiftStartDate && x.shiftEndDate) {
        if (x.shiftStartDate.length === 10) {
          x.shiftStartDate = x.shiftStartDate + " 00:00 AM";
          x.shiftEndDate = x.shiftEndDate + " 00:00 AM";
        }
        x.shiftStartDate = formatDateTimeForApi(new Date(x.shiftStartDate ?? ''));
        x.shiftEndDate = formatDateTimeForApi(new Date(x.shiftEndDate ?? ''));
      }
      return x;
    });

    data.personalExpenses = data.personalExpenses?.map((x, index) => {
      x.d407id = saveAction.action === "update" ? x.d407id : undefined;
      x.personalExpensesId = undefined;
      return x;
    });

    data.tailDetails = data.tailDetails?.map((x, tIndex) => {
      x.tailDetailId = undefined;
      x.orderNo = tIndex + 1;
      x.apu = x.apu ? x.apu.toString() : '';
      x.oilEngine1 = x.oilEngine1 ? x.oilEngine1.toString() : '';
      x.oilEngine2 = x.oilEngine2 ? x.oilEngine2.toString() : '';
      x.oilEngine3 = x.oilEngine3 ? x.oilEngine3.toString() : '';
      x.oilEngine4 = x.oilEngine4 ? x.oilEngine4.toString() : '';
      x.hydEngine1 = x.hydEngine1 ? x.hydEngine1.toString() : '';
      x.hydEngine2 = x.hydEngine2 ? x.hydEngine2.toString() : '';
      x.hydEngine3 = x.hydEngine3 ? x.hydEngine3.toString() : '';
      x.hydEngine4 = x.hydEngine4 ? x.hydEngine4.toString() : '';
      x.materialsNitrogen = x.materialsNitrogen ? x.materialsNitrogen.toString() : '';
      x.materialsOxygen = x.materialsOxygen ? x.materialsOxygen.toString() : '';
      x.materialsOther = x.materialsOther ?? '';
      x.d407id = saveAction.action === "update" ? x.d407id : undefined;
      x.workDetails = x.workDetails?.map((w, wIndex) => {
        w.tailDetailId = undefined;
        w.workDetailId = undefined;
        w.totalTime = w.totalTime ? w.totalTime.toString() : '';
        w.orderNo = wIndex + 1;
        return w;
      })
      return x;
    });

    const result = await customerWorksheetService.saveCustomerWorksheet(data);

    isSaveClickedRef.current = false;

    if (result?.status !== resultStatus.failed) {
      const [updatedData, webHookSave] = await Promise.all([await customerWorksheetById(result.id.toString(), true), await webhookService.invokeD407Save(data)]);
      if (updatedData) {
        setFileTitle(updatedData);
        setSaving(false);
        setWorksheet(JSON.parse(JSON.stringify(updatedData)));
        //@ts-ignore
        setWorkSheetId(updatedData.d407id.toString())
        //@ts-ignore
        setSaveAction({ formProps: undefined, action: undefined, status: result?.status });
        setPrint(prev => ({ ...prev, formikHelpers: formikHelpers }))
      }
    } else {
      setSaving(false);
      //@ts-ignore
      setSaveAction(prev => ({ ...prev, status: result?.status }));
    }
  }

  async function customerWorksheetById(id: string, loadAfterSave: boolean = false) {
    if (!id) {
      setWorksheet(factories.getD407DynamicData());
      setLock(false);
      setWorkSheetId(undefined);
    }
    else {
      const data = await customerWorksheetService.getByIdAsync(+id) ?? factories.getD407DynamicData();
      return reArrangeD407Data(data, loadAfterSave);
    }

  }

  async function reArrangeD407Data(data: FeamEntities.ID407Data, loadAfterSave: boolean = false, skipStationApprover: boolean = false) {
    let stationApprovers;
    if (data && data.stationId) {
      stationApprovers = await userRoleService.getStationApproversByStation(data.stationId);
    }
    data.entryDate = (new Date(data.entryDate ?? '')).toString();
    data.systemDateTime = (new Date(data.systemDateTime ?? '')).toString();
    data.dateCompleted = (new Date(data.dateCompleted ?? '')).toString();
    data.username = loginUserDetail.email;
    data.updatedBy = loginUserDetail.displayName;
    data.displayName = loginUserDetail.displayName;
    if (data.mechanicsAssigneds) {
      for (let index = 0; index < data.mechanicsAssigneds.length; index++) {
        if (data.mechanicsAssigneds[index].shiftStartDate && data.mechanicsAssigneds[index].shiftEndDate) {
          data.mechanicsAssigneds[index].shiftStartDate = (new Date(data.mechanicsAssigneds[index].shiftStartDate ?? '')).toString();
          data.mechanicsAssigneds[index].shiftEndDate = (new Date(data.mechanicsAssigneds[index].shiftEndDate ?? '')).toString();
        }
      }
    }
    if (!loadAfterSave && !skipStationApprover) {
      setWorksheet(JSON.parse(JSON.stringify(data)));
      setLock(false);
      //@ts-ignore
      setWorkSheetId(data.d407id)
      if (!isPrint && stationApprovers && data.approvedBy && (!feamAuth.user || (feamAuth.user && (!feamAuth.user.roleTypeIds || feamAuth.user.roleTypeIds.length === 0 || feamAuth.user.roleTypeIds.includes(userRoleType.billingApprover))))) {
        if ((!feamAuth.user || feamAuth.user.stationIds?.includes(data.stationId ?? 0)) && stationApprovers.find(x => x.fullName?.toLocaleLowerCase().trim() === data.approvedBy?.toLocaleLowerCase().trim())) {
          setLock(true);
        }
      }
      if (data.status === processingStatusType.markedforBilling && (!feamAuth.user || (feamAuth.user && (!feamAuth.user.roleTypeIds || feamAuth.user.roleTypeIds.length === 0 || (!feamAuth.user.roleTypeIds.includes(userRoleType.supperAdmin) && !feamAuth.user.roleTypeIds.includes(userRoleType.powerUser)))))) {
        setLock(true);
      }
    }
    else {
      return data;
    }
  }
  async function extractDataFromAD() {
    if (!accounts[0]) {
      return;
    }
    const response = await Promise.all([feamEmployeeService.getAll(), stationService.getAll(), maintenanceTypeService.getAll(), customerService.getAll()]);
    const employees = response[0];
    const stations = response[1];
    const maintenanceTypes = response[2];
    const employee = employees.find(x => x.workEmail?.trim().toLocaleLowerCase() === accounts[0].username.trim().toLocaleLowerCase());
    const stationId = (employee && stations.find(x => x.text === employee.location)) ? stations.find(x => x.text === employee.location)?.value : 120;//'MIA';
    const stationCode = stations.find(x => x.value === stationId?.toString())?.text ?? 'MIA';
    const feamEmployees: FeamEntities.IFeamEmployeeForAutoInput[] = employees.map((emp: FeamEntities.IFeamEmployee) => {
      return { ...emp, value: emp.employeeCode, text: getText(emp) };
    })
    const maintenanceTypesForDropdown = maintenanceTypes.map<IDropdown>(
      (d: FeamEntities.IMaintenanceType): IDropdown => {
        return { text: d.name, value: d.maintenanceTypeId?.toString() };
      }
    );
    setUsers(feamEmployees)

    setLoginUserDetail({
      email: accounts[0].username, displayName: accounts[0].name ?? "", stationCode: stationCode,
      //@ts-ignore
      stationId: stationId
    });
    setWorksheet(p => ({
      ...p,
      username: accounts[0].username,
      preparedBy: accounts[0].name ?? "",
      updatedBy: accounts[0].name ?? "",
      displayName: accounts[0].name ?? "",
      //@ts-ignore
      stationId: +stationId,
      stationCode: stationCode
    }));
    setCustomers(response[3]);
    setStations(stations);
    setMaintenanceType({ maintenanceTypesForDropdown: maintenanceTypesForDropdown, maintenanceTypes: maintenanceTypes });
    setIsLoaded(true);
  }
  function getText(item: FeamEntities.IFeamEmployee) {
    //@ts-ignore
    return (item.employeeCode + "-" + item.firstName + " " + item.lastName ?? "").trim();
  }
  function setFileTitle(updatedData: FeamEntities.ID407Data) {
    //@ts-ignore
    let date = new Date(updatedData.entryDate);
    if (date.toString().includes('GMT') || date.toString().includes('T')) {
      //@ts-ignore
      date = [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("");
    }
    //@ts-ignore
    document.title = `${updatedData.aogOrOssNumber ? updatedData.aogOrOssNumber + "-" : ""}${updatedData.stationCode}-${customers.find(x => x.value == updatedData.customerId)?.text ?? ""}-${maintenanceType.maintenanceTypesForDropdown.find(x => x.value == updatedData.maintenanceTypeId)?.text ?? ""}-${date}-${updatedData.d407id}`;
  }

  function approveHandler(
    e: React.ChangeEvent<HTMLInputElement>,
    formProps: FormikProps<FeamEntities.ID407Data>
  ) {
    if (e.target.checked) {
      formProps.setFieldValue("status", processingStatusType.approved);
      formProps.setFieldValue("approvedBy", accounts[0].name ?? "");
    } else {
      formProps.setFieldValue("status", null);
      formProps.setFieldValue("approvedBy", "");
    }
    formProps.handleChange(e);
  }
}
