import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageLayout from './PageLayout';
import RequestInterceptors from './RequestInterceptors';
import { AdvancedSearchPage, BillingProcessingStatusPage, BillingReviewPage, CustomerWorkSheetPage, DynamicCustomerWorkSheetPage, FeamHelpPage, ReportPage, TimeAndLaborPage, UserManagementPage } from './components/pages';
import { useEffect, useState } from 'react';
import CustomerDataSyncPage from './components/pages/CustomerDataSyncPage';
import ReportPage2 from './components/pages/ReportPage2';
import ProtectedRoute from './ProtectedRoute';
import { feamUserInfo, userRoleType } from './models/constants';
import { FeamEntities } from './models/feam-entities';
import UnauthorizedAccessPage from './components/pages/UnauthorizedAccessPage';

if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
    console.warn = function () { };
    console.info = function () { };
    console.error = function () { };
    console.debug = function () { };
}
function App() {
    const [user, setUser] = useState<FeamEntities.ID407userRole | undefined>(localStorage.getItem(feamUserInfo) ? JSON.parse(localStorage.getItem(feamUserInfo) ?? '') : undefined);
  
    useEffect(() => {
        //Disable Ctrl+P
        document.addEventListener("keydown", function (event) {
            if (event.ctrlKey && event.key === "p") {
                event.preventDefault();
            }
        });
    }, [])
    return (
        <PageLayout>
            <RequestInterceptors>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString(), userRoleType.stationAdmin.toString(), userRoleType.stationApprover.toString(), userRoleType.powerUser.toString()]} allowNormalUser={true}>
                               <CustomerWorkSheetPage />
                            </ProtectedRoute>} />
                        <Route path="/dynamic" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString(), userRoleType.stationAdmin.toString(), userRoleType.stationApprover.toString(), userRoleType.powerUser.toString()]} allowNormalUser={true}>
                               <DynamicCustomerWorkSheetPage />
                            </ProtectedRoute>} />
                        <Route path="/search" element={<AdvancedSearchPage />} />
                        <Route path="/reports" element={<ReportPage />} />
                        <Route path="/reports2" element={<ReportPage2 />} />
                        <Route path="/unauthorized" element={<UnauthorizedAccessPage />} />
                        <Route path="/billing" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString(), userRoleType.billingApprover.toString(), userRoleType.powerUser.toString()]} allowNormalUser={false}>
                               <BillingReviewPage />
                            </ProtectedRoute>} />
                        <Route path="/help" element={<FeamHelpPage />} />
                        <Route path="/admin/status" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString(), userRoleType.billingApprover.toString(), userRoleType.powerUser.toString()]} allowNormalUser={false}>
                                <BillingProcessingStatusPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/admin/users" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString()]} allowNormalUser={false}>
                                <UserManagementPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/admin/timeandlabor" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString(), userRoleType.billingApprover.toString(), userRoleType.powerUser.toString(), userRoleType.stationAdmin.toString()]} allowNormalUser={false}>
                                <TimeAndLaborPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/admin/customersync" element={
                            <ProtectedRoute user={user} roles={[userRoleType.supperAdmin.toString()]} allowNormalUser={false}>
                                <CustomerDataSyncPage />
                            </ProtectedRoute>
                        } />
                       
                    </Routes>
                </BrowserRouter>
            </RequestInterceptors>
        </PageLayout>
    )
}

export default App;
