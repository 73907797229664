import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { CwsGeneralHeader } from "../common";
import { useEffect, useState } from "react";
import { FeamEntities, IDropdown, IMultiSelectDropdown } from "../../../models/feam-entities";
import UsersManagementSearch from "./UsersManagementSearch";
import { feamEmployeeService, resultStatus, userRoleService, userRoleTypeService } from "../../../services";
import stationService from "../../../services/station-service";
import { useTokenState } from "../../../RequestInterceptors";
import UserManagementGrid from "./UserManagementGrid";
import * as factories from "../../../models/factories";
import { useFeamAuth } from "../../hooks";
import UserManagementForm from "./UserManagementForm";
import * as utils from "../../../models/feam-utils";

export default function UsersManagementContainer() {
    const [d407UserRole, setD407UserRole] = useState<FeamEntities.ID407userRole | undefined>(undefined)
    const [d407UserRoles, setD407UserRoles] = useState<FeamEntities.ID407userRole[]>([])
    const [feamEmployees, setFeamEmployees] = useState<FeamEntities.IFeamEmployee[]>([])
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [dropdownData, setDropdownData] = useState<{ searchableUsers: IDropdown[], users: IDropdown[], userRoleTypes: IDropdown[], stations: IDropdown[], multiStations: IMultiSelectDropdown[] }>({ searchableUsers: [], users: [], userRoleTypes: [], stations: [], multiStations: [] })
    const [filter, setFilter] = useState<FeamEntities.IUserManagementFilter>({ employeeCode: '', stationId: undefined, userRoleTypeId: undefined, isInActive: false })
    const { isTokenSet } = useTokenState();
    const userAuth = useFeamAuth();

    useEffect(() => {
        const fatchData = async () => {
            setLoading(true);
            await loadData();
            setLoading(false);
        }
        fatchData();
    }, [isTokenSet])

    return (
        <>
            <LoadingOverlayWrapper className="feam-postion-fixed" active={loading || searching} text={"Loading Content..."} spinner>
                <div className="container cws-advance-search feam-m-5">
                    <CwsGeneralHeader title="User Management" />
                    <div style={{ display: d407UserRole ? "none" : "block" }}>
                        <UsersManagementSearch onSearch={searchHandler} onClear={clearHandler} onAdd={() => { setD407UserRole(factories.getD407userRole()) }} searching={searching} stations={[...dropdownData.stations]} userRoleTypes={[...dropdownData.userRoleTypes]} users={[...dropdownData.searchableUsers]} />
                    </div>
                    {!d407UserRole && <UserManagementGrid d407UserRoles={d407UserRoles} feamEmployees={feamEmployees} userRoleTypes={dropdownData.userRoleTypes} stations={dropdownData.stations} onEdit={(e) => { setD407UserRole(e) }} onDelete={deleteHandler} filter={filter} />}
                    {d407UserRole && <UserManagementForm d407UserRole={d407UserRole} onSave={saveHandler} onBack={() => { setD407UserRole(undefined) }} dropdownUsers={[...getFilterUsers()]} users={feamEmployees} multiStations={dropdownData.multiStations} dropdownUserRoleTypes={dropdownData.userRoleTypes} saving={saving} />}
                </div>
            </LoadingOverlayWrapper>
        </>
    )

    function getFilterUsers(): IDropdown[] {
        const empCodes: (string | undefined)[] = d407UserRoles.map(x => x.employeeCode?.trim());
        return dropdownData.users.filter(x => (d407UserRole && d407UserRole.d407userRoleId && d407UserRole.d407userRoleId > 0) ? (x.value?.trim() === d407UserRole.employeeCode?.trim() || !empCodes.includes(x.value?.trim())) : !empCodes.includes(x.value?.trim()));
    }

    async function loadData() {
        const result = await Promise.all([feamEmployeeService.getAll(), userRoleTypeService.get(), stationService.getAll()]);
        const employees = [...result[0].sort((a, b) => {
            const aFullName = a.firstName + " " + a.lastName;
            const bFullName = b.firstName + " " + b.lastName;
            return aFullName.localeCompare(bFullName);
        })];
        const dropdownEmployees = employees.map((e: FeamEntities.IFeamEmployee): IDropdown => {
            return { text: e.employeeCode?.trim() + "-" + e.firstName + " " + e.lastName, value: e.employeeCode?.trim() };
        });

        const searchableUsers = employees.map((e: FeamEntities.IFeamEmployee): IDropdown => {
            return { text: (e.employeeCode?.trim() + "-" + e.firstName + " " + e.lastName).trim(), value: (e.employeeCode?.trim() + "-" + e.firstName + " " + e.lastName).trim() };
        });
        const userRoleTypes = result[1].map((e: FeamEntities.ID407userRoleType): IDropdown => {
            return { text: e.name, value: e.d407userRoleTypeId.toString().trim() };
        });
        //@ts-ignore
        const multiStations: IMultiSelectDropdown[] = result[2].map((x) => {
            return { label: x.text, value: x.value }
        }
        )
        await searchHandler({ isInActive: false });
        setDropdownData({ searchableUsers: [...searchableUsers], users: [...dropdownEmployees], userRoleTypes: userRoleTypes, stations: result[2], multiStations: multiStations })
        setFeamEmployees(employees);
    }

    async function searchHandler(filter: FeamEntities.IUserManagementFilter) {
        if (filter.userRoleTypeId === 1 && filter.stationId) {
            window.alert('Super Admin role has accessed of all stations. Please do not select station when user role type is Super Admin.');
            return;
        }
        setSearching(true);
        const result = await userRoleService.searchUserRoles(filter);
        setD407UserRoles([...result]);
        setFilter(filter);
        setSearching(false);
    }

    async function deleteHandler(e: FeamEntities.ID407userRole) {
        if (window.confirm(`Are you sure? You would like to delete user role.`)) {
            setLoading(true);
            //@ts-ignore
            await userRoleService.dateUserRoles(e.d407userRoleId, userAuth.user?.fullName);
            window.alert('User role has been deleted successfully.')
            await searchHandler(filter);
            setLoading(false);
        }
    }

    async function clearHandler() {
        setD407UserRole(undefined);
        setD407UserRoles([]);
        await searchHandler({ isInActive: false });
        setFilter({ employeeCode: '', stationId: undefined, userRoleTypeId: undefined, isInActive: false });
    }

    async function saveHandler(e: FeamEntities.ID407userRole) {
        setSaving(true);
        if(!utils.trimObjectProps(e)){
            setSaving(false);
            alert('Failed to save user role');
            return;
        }
        const isSave = !e.d407userRoleId || e.d407userRoleId === 0;
        if (isSave) {
            e.createdBy = userAuth.user?.fullName;
        }
        e.updatedBy = userAuth.user?.fullName;

        e.d407userRoleStationMappers?.forEach((s) => {
            s.createdBy = userAuth.user?.fullName;
            s.updatedBy = userAuth.user?.fullName;
        });

        e.d407userRoleTypeMappers?.forEach((s) => {
            s.createdBy = userAuth.user?.fullName;
            s.updatedBy = userAuth.user?.fullName;
        });
        const result = await userRoleService.saveUserRole(e);
        if (result.status !== resultStatus.failed) {
            alert(`User role has been ${isSave ? 'saved' : 'updated'} successfully.`)

        } else {
            alert(`Failed to ${isSave ? 'saved' : 'updated'} user role.`)
        }
        await searchHandler(filter);
        setSaving(false);
        setD407UserRole(undefined);
    }

}