import React from 'react';
import { Navigate } from 'react-router-dom';
import { FeamEntities } from './models/feam-entities';

interface ProtectedRouteProps {
    user: FeamEntities.ID407userRole | undefined;
    roles: string[];
    children: React.ReactNode;
    allowNormalUser: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ user, roles,allowNormalUser, children }) => {
    if (!allowNormalUser && (!user || !user.roleTypeIds || !user.roleTypeIds.some(roleId => roles.includes(roleId.toString())))) {
        return <Navigate to="/unauthorized" />;
    }
    return <>{children}</>;
};

export default ProtectedRoute;
