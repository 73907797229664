import { Fragment, useEffect, useState } from "react"
import { IProps } from "../../../models/feam-entities"
import { formatDateTime } from "../../../models/feam-utils"
import * as BootStrapIcon from "react-bootstrap-icons";
import { usePrint } from "../../../PageLayout";

export default function TimeAndLabourDetail(props: IProps.ITimeAndLabourDetailProps) {
    const [showDetail, setShowDetail] = useState(false);

    const { isPrint } = usePrint();
    useEffect(() => {
        setShowDetail(isPrint);
    }, [isPrint])

    return (
        <Fragment>
            <tr>
                <td className="text-nowrap">{!showDetail ? <BootStrapIcon.PlusCircle size={16} onClick={() => { setShowDetail(true) }} className="text-primary me-1" /> : <BootStrapIcon.DashCircle size={16} className="text-primary  me-1" onClick={() => { setShowDetail(false) }} />}{props.timeAndLabour.mechanicName}</td>
                <td>{props.timeAndLabour.employeeCode ? props.timeAndLabour.employeeCode : 'Name was not selected from List'}</td>
                <td>{props.timeAndLabour.employeeCode ? props.timeAndLabour.employeeType : 'CON'}</td>
            </tr>
            {showDetail && <tr>
                <td colSpan={3}>
                    <table className="table m-0 p-0 customer-worksheet-time-labor">
                        <thead className="header-col">
                            <tr className="text-uppercase header-col">
                                <th scope="col" className="header-col text-uppercase ">Date</th>
                                <th scope="col" className="header-col text-uppercase ">D407 HOURS</th>
                                <th scope="col" className="header-col text-uppercase ">Clocked HOURS</th>
                                <th scope="col" className="header-col text-uppercase ">VARIANCE</th>
                                <th scope="col" className="header-col text-uppercase ">D407Ids</th>

                            </tr>
                        </thead>
                        <tbody>
                            {props.timeAndLabour.detail.map((dd, j) => {
                                return (
                                    <Fragment key={`timeandlabor_detail_${j}`}>
                                        <tr >
                                            <td>{formatDateTime(new Date(dd.date), true)}</td>
                                            <td>{dd.workedHours}</td>
                                            <td>{dd.regularDuration??"No clocked hours for this day"}</td>
                                            <td>{dd.variance}</td>
                                            <td>{dd.d407Ids?.map((id, j) => {
                                                return (<> <a className="link-offset-2 link-underline link-underline-opacity-100" href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        //@ts-ignore 
                                                        props.selectedId(id?.toString());
                                                    }}>{id}</a>{dd.d407Ids && j === dd.d407Ids.length - 1 ? '' : ','}</>)
                                            })}</td>
                                        </tr>
                                    </Fragment>)
                            })}
                        </tbody>
                    </table>
                </td>
            </tr>}

        </Fragment>
    )


}