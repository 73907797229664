
import * as BootStrapIcon from 'react-bootstrap-icons';

export default function UnauthorizedAccessPage() {
  return (
    <div className="container cws-advance-search customer-container-width feam-m-5 cws-advance-search" >
      <div className="d-flex flex-row justify-content-between align-items-center customer-worksheet-header mb-3">
      <div className="customer-worksheet-logo-container">
        {/* <img alt="Unauthorized Access" src="path/to/unauthorized-user-image.png" className="customer-worksheet-logo" /> */}
      </div>
     
      <div className="d-flex flex-column customer-worksheet-version-container">
        <span className="fs-6 fw-bold">FEAM-407</span>
        <span className="customer-worksheet-version-fontsize">Web Version V5</span>
        <span className="customer-worksheet-version-fontsize">8-01-2024</span>
      </div>
      </div>
      <div className="d-flex flex-column justify-content-between align-items-center customer-worksheet-header mb-3">
      <div className="d-flex fs-4 fw-bold flex-row justify-content-center align-items-center text-uppercase mb-5 gap-3">
       <BootStrapIcon.ExclamationTriangle className="text-danger" size={100} />
        <span className="text-danger">Unauthorized Access</span>
      </div>
      <div className="d-flex fs-4 flex-fill justify-content-center fst-italic text-secondary">
        <span>You do not have permission to view this page.</span>
      </div>
      </div>
    </div>
  );
}