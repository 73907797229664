import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class FeamEmployeeService {
  #rootPath: string = "feamEmployee";
  async getAll(): Promise<FeamEntities.IFeamEmployee[]> {
    try {
      const res = await apiClient.get<FeamEntities.IFeamEmployee[]>(
        this.#rootPath
      );
      const data = res.data ?? [];
      return data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const feamEmployeeService = new FeamEmployeeService();
export default feamEmployeeService;
