import { useEffect, useState } from "react";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import CwsMechanicsAssignedWithOutWSForm from "./CwsMechanicsAssignedWithOutWSForm";
import CwsMechanicsAssignedWSForm from "./CwsMechanicsAssignedWSForm";
import { useIsAuthenticated } from "@azure/msal-react";
import { payTypeService, workTitleService } from "../../../services";
import { FieldArray, FieldArrayRenderProps } from "formik";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { NoRecordFound } from "../common";
import CwsMechanicsAssignedForAogForm from "./CwsMechanicsAssignedForAogForm";

export default function CwsMechanicsAssignedForm(props: IProps.ICwsMechanicsAssignedFormProps<FeamEntities.IMechanics>) {
  const [dropdownData, setDropdownData] = useState<{ payTypes: IDropdown[], workTitles: IDropdown[] }>({ payTypes: [], workTitles: [] });
  const [, setDeleteToggle] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { formProps, maintenanceTypes } = props;
  useEffect(() => {
    if (isAuthenticated && (dropdownData.payTypes.length === 0 && dropdownData.workTitles.length === 0)) {
      const fetchData = async () => {
        const result = await Promise.all([payTypeService.getAll(), workTitleService.getAll()]);
        setDropdownData({ payTypes: result[0], workTitles: result[1] })
      }
      fetchData()
    }
  }, [isAuthenticated])
  return (
    <FieldArray
      name="mechanics"
      render={(arrayHelper: FieldArrayRenderProps) =>
      (
        <div className="mechanics-form" style={{ minHeight: "250px" }}>
          <div className="d-flex  justify-content-between align-items-center header-col w-100 p-1 mb-2 rounded text-uppercase">
            <div className="d-flex gap-3 align-items-center justify-content-start"><span className="fw-bold">MECHANICS ASSIGNED</span>
              <div className="d-flex align-items-center">
                <div className="form-check d-inline-flex gap-1 align-items-center">
                  {/*//@ts-ignore */}
                  <input type="checkbox" id="isWorkShift" name="isWorkShift" checked={formProps.values.isWorkShift ?? false} onChange={(e) => { props.formProps.values.mechanics = []; formProps.handleChange(e); }} className="form-check-input" disabled={formProps.values.maintenanceTypeId && ["AOG", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name)} />
                  <label className="customer-worksheet-ws-label form-check-label" htmlFor="isWorkShift">
                    Day/Week Shift
                  </label>
                </div>
              </div>
            </div>
            <BootStrapIcon.PlusCircleFill role="button" className="text-secondary" size={16} onClick={() => { arrayHelper.push({}) }} title="Add Mechanics" />
          </div>
          {(!formProps.values.mechanics || formProps.values.mechanics.length === 0) && (
            <NoRecordFound message="No Mechanics Assigned found" className="mb-3" />)}
          {formProps.values.mechanics !== undefined && formProps.values.mechanics.length > 0 &&
            <>
              {!formProps.values.isWorkShift && <CwsMechanicsAssignedWithOutWSForm {...props} deleteHandler={deleteHandler} payTypes={[...dropdownData.payTypes]} workTitles={[...dropdownData.workTitles]} onAutoCompleteChangeHandler={autoCompleteChangeHandler} />}
              {formProps.values.isWorkShift && (!formProps.values.maintenanceTypeId || (formProps.values.maintenanceTypeId && !["AOG", "MRR", "FLT-Flight Mechanic","OSS","INT"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? ""))) && <CwsMechanicsAssignedWSForm {...props} deleteHandler={deleteHandler} payTypes={[...dropdownData.payTypes]} workTitles={[...dropdownData.workTitles]} onAutoCompleteChangeHandler={autoCompleteChangeHandler} />}
              {["AOG", "MRR", "FLT-Flight Mechanic","OSS","INT"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? "") && <CwsMechanicsAssignedForAogForm {...props} deleteHandler={deleteHandler} payTypes={[...dropdownData.payTypes]} workTitles={[...dropdownData.workTitles]} onAutoCompleteChangeHandler={autoCompleteChangeHandler} />}
            </>
          }
        </div>
      )} />
  )

  function deleteHandler(index: number) {
    const name = formProps.values.mechanics[index].mechanicName ?? '';
    if (name !== '') {
      if (window.confirm(`Are you sure! You want to delete the "${name}" from Mechanics Assigned list.`)) {
        formProps.values.mechanics?.splice(index, 1);
        setDeleteToggle(prev => !prev);
      }
    } else {
      formProps.values.mechanics?.splice(index, 1);
      setDeleteToggle(prev => !prev);
    }
  }
  function autoCompleteChangeHandler(id: string, newValue: FeamEntities.IFeamEmployeeForAutoInput | string) {
    const idSplit = id.split('.');
    const employeeCodeId = [idSplit[0], idSplit[1], "employeeCode"].join('.');
    const feamEmployeeId = [idSplit[0], idSplit[1], "feamEmployeeId"].join('.');
    if (typeof newValue === 'string') {
      // @ts-ignore
      formProps.setFieldValue(id, newValue);
    } else if (newValue && newValue.employeeCode) {
      // Create a new value from the user input
      // @ts-ignore
      formProps.setFieldValue(id, (newValue.firstName + " " + newValue.lastName ?? "").trim());
      formProps.setFieldValue(employeeCodeId, newValue.employeeCode);
      formProps.setFieldValue(feamEmployeeId, newValue.feamEmployeeId);
    } else {
      formProps.setFieldValue(id, newValue === null ? "" : newValue.value ?? "");
    }
  }
}